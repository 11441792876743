.filter-container {
    position: absolute;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    right: 0;
    top: calc(100% + 10px);
	border: 1px solid #eee;
    min-width: 400px;

    &:after, &:before {
        bottom: 100%;
        right: 8px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 8px;
        margin-left: -8px;
    }

    &:before {
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: #eee;
        border-width: 9px;
        margin-right: -1px;
    }

    .gr-form-element {
        margin-bottom: 1rem;
    }    

    .actions {
        justify-content: center;
        margin: 2rem -1rem 0 -1rem;
        border-top: 1px solid #E6E6E6;
        padding: 1rem 0 0;
    }
}

/* Inline Filters - Additional filters */
.additional-filter-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    position: absolute;
    z-index: 15;
    width: 100%;
    right: 0;
    top: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 9px 12px 0px rgba(89, 96, 100, 0.36), 0px 3px 16px 0px rgba(89, 96, 100, 0.31), 0px 5px 6px 0px rgba(89, 96, 100, 0.51);

    h4 {
        margin: 0;
        padding: 8px 12px;
        font-weight: 700;
    }
}

/* Multiselect container */
.multiselect-container {
    /*position: relative; */
    padding: 8px 12px;

    &:hover {
        background-color: #007FA3;

        .multiselect__input {
            background-image: url(../images/icon_right_white.svg);

            span {
                color: #fff;
            }
        }
    }

    .multiselect__input {
        /* border: 1px solid #9AA5B1;
        padding: 4px 8px; 
        border-radius: 4px; */
        width: 100%;
        background-color: transparent;
        background-image: url(../images/icon_right.svg);
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 24px;
        cursor: pointer;

        &.disable_ms {
            background-color: #E9E9E9;
            border-color: #C7C7C7;
        }

        span {
            background: transparent;
            line-height: 1.5;
            color: #252525;
            font-size: 14px;
            margin-right: 8px;
            cursor: pointer;
        }
    }

    .multiselect__options {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        border-radius: 4px;
        z-index: 20;
        box-shadow: 0px 9px 12px 0px rgba(89, 96, 100, 0.36), 0px 3px 16px 0px rgba(89, 96, 100, 0.31), 0px 5px 6px 0px rgba(89, 96, 100, 0.51);

        .back-btn {
            margin: 8px 12px 8px 8px;
            padding-left: 24px;
            font-weight: 700;
            color: #252525;
            background: url(../images/icon_left.svg) no-repeat left center;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 16px 8px 16px 16px;
            max-height: 330px;
            min-height: 172px;
            overflow: auto;

            li {
                color: #020917;
                margin-bottom: 16px;
                display: flex;
                font-size: 1rem;
                align-items: flex-start;
                line-height: 1.43;
                letter-spacing: 0.15px;

                &:last-child {
                    margin-bottom: 0;
                }

                input {
                    margin-right: 8px;
                    margin-top: 5px;
                    border: 2px solid #9AA5B1;
                }

                label {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1;

                    svg {
                        width: 20px;
                        height: auto;
                        margin-right: 8px;
                    }
                }

                .sub-title {
                    font-size: 1rem;
                    line-height: 1.43;
                    letter-spacing:  0.15px;
                    font-weight: 400;
                    color: #020917;
                    display: block;
                    margin-top: 0.5rem;
                }
            }
        }

        .dd-actions {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            border-top: 1px solid #CFD8DC;
            padding: 16px;

            .link-btn {
                font-weight: 600;
                padding: 0;
            }
        }
    }
} 

.selected-filters {
    padding: 6px 0 16px;
}
    
.multiselect__selections, .selected-filters {
    display: flex;
    width: 100%;    
    flex-wrap: wrap;
    align-items: center;

    .label {
        margin-right: 16px;
        font-weight: bold;
        font-size: 12px;
    }

    .chip {
        padding: 4px 8px;
        background: #CFD8DC;
        margin-right: 8px;
        margin-bottom: 5px;
        border-radius: 2px;
        display: inline-flex;
        align-items: center;
        font-size: 11px;
        line-height: 1.4;
        color: #263238;
        border-radius: 30px;

        .custom-close {
            margin: 1px 0 0 5px;
        }
    }
}

.filter-checks {
    display: flex;
    justify-content: space-between;
}

.checkbox-container {
    display: flex;

    .gr-form-element {

        label {
            white-space: nowrap;
            display: inline-flex;
            align-items: center;
        }

        img {
            margin-right: 4px;
            vertical-align: middle;
        }
    }
}