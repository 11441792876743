.donut-chart {
    width: 150px;
    font-size: 1rem;
    margin: 0 auto;
    //animation: donutfade 1s;
    position: relative;

    > svg {
        transform: rotate(90deg);
    }

    .score {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.125rem;
        font-family: Inter;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0em;
        text-align: center;
    }
}

@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
    0% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}

.donut-ring {
    stroke: #EBEBEB;
}

.donut-segment {
    transform-origin: center;
    stroke: $color-theme-sys-light-secondary;
}

.donut-segment-2 {
    stroke: $color-theme-sys-light-secondary;
   // animation: donut1 3s;
}

@keyframes donut1 {
   to {
    stroke-dasharray: 1000 0;
   }
}