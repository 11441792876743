.assignment-metadata {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.assignment-row {
    cursor: pointer;

    &:hover {
        background: $color-theme-surfaces-light-surface-hover;
    }
}