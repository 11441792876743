
.create-assignment-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    grid-gap: 0.5rem;
    align-items: flex-end;
    margin: 1rem 0;
    
    .assignment-name-block {
        grid-column: 1 / span 3 !important;
        grid-row: 1;
        padding: 0;
        margin: 0;
    }

    .assignment-cart {
        grid-column: 5 / span 4;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;
    }
}

.create-assignment {
    padding: 2rem 5rem;

    header {
        display: grid;
        grid-template-columns: 2fr 1fr;

        .date-icon {
            width: 60px;
            height: 60px;
            background-color: #e9e9e9;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 36px;

            &.date-added {
                background-color: #1E496C;
                color: #fff;    
                flex-direction: column;            
            }

            svg {
                fill: #6a7070;
            }

            .month {
                font-size: 12px;
                display: block;
                text-transform: uppercase; 
            }
            
            .day {
                font-size: 18px;
                display: block; }
        }        

        .settings-help {
            align-self: end;
            justify-self: end;
            display: flex;
            align-items: center;

            .gr-btn {
                margin: 0 9px;

                &.link-btn {
                    color: #252525;

                    svg {
                        fill: #252525;
                    }
                }
            }
        }

        h2 {
            color: #252525;
            font-size: 24px;
            font-weight: 400;
            margin: 0 !important;
            line-height: 1;
        }

        .assignment-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .tag-homework {
                font-size: 14px;
                margin-right: 3px;
                padding: 3px 3px 3px 0;

                &:before {
                    margin-right: 8px;
                }
            }

            .left {
                display: flex;
                align-items: center;

                .due-date {
                    padding: 3px 16px;
                    display: flex;
                    align-items: center;

                    .MuiFormControl-root {
                        margin: 0;
                    }
                }
                .gr-label {
                    display: inline-block;
                    margin-right: 3px;
                    font-size: 14px;
                    font-weight: 600;
                    color:#3E4C59;
                }

            }

            .link-btn {
                padding: 3px 6px;
            }
        }

        .edit-name-form {
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            margin: 0.75rem 0 0.25rem;
            padding-right: 0.5rem;
        }
    }

    .assignment-creation-content {
        
    }
}

.link-btn {
    border: 0;
    padding: 6px;
    min-width: 0;
    font-size: 14px;
    color: #047A9C;
    margin: 0 6px;

    svg {
        fill: #047A9C;
        margin-right: 8px;
    }

    &:disabled {
        background-color: transparent !important;
        color: #909090 !important;

        svg {
            fill: #909090 !important;
        }
    }

    &:hover, &:focus {
        outline: none;
        box-shadow: none;
        border: 0
    }
}

.assignment-manager-list-view {
    &.review-table {
        box-shadow: none;
        background-color: #fff;

        .clone-table {
            .gr-checkbox {
                label {
                    text-indent: -9999px;
                }
            }
        }
    }

    .review-list-header {
        display: grid;
        grid-template-columns: 70px auto;
        grid-column-gap: 12px;
        background: #f5f5f5;
        border-top: 1px solid #E4E7EB;
        border-bottom: 1px solid #E4E7EB;
        height: 50px;

        .header-group {
            display: grid;
            grid-template-columns: repeat(32, 1fr);
            grid-gap: 12px;

            .sortableColumn {
                padding: 0;
            }

            .selections {              

                .gr-checkbox {
                    margin: 0 10px 0 0;
                }
            }

            .name {
                grid-column: 3 / span 9;
            }

            .objective {
                grid-column: 12 / span 8;
            }

            .type {
                grid-column: 20 / span 5;
            }
            
            .time {
                grid-column: 25 / span 4;
            }
        }
    }
}