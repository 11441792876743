
// Do not edit directly
// Generated on Wed, 13 Dec 2023 19:56:20 GMT

$color-theme-white: #ffffffff;
$color-theme-black: #000000ff;
$color-theme-indigo: #1d3557ff;
$color-theme-text-lighttheme-default: #020917ff;
$color-theme-text-lighttheme-muted: #585858ff;
$color-theme-text-darktheme-default: #fefefeff;
$color-theme-text-darktheme-muted: #b5b5b5ff;
$color-theme-sys-light-primary: #007e53ff;
$color-theme-sys-light-on-primary: #ffffffff;
$color-theme-sys-light-primary-container: #5bdda0ff;
$color-theme-sys-light-on-primary-container: #005234ff;
$color-theme-sys-light-secondary: #5066c5ff;
$color-theme-sys-light-on-secondary: #ffffffff;
$color-theme-sys-light-secondary-container: #b9c3ffff;
$color-theme-sys-light-on-secondary-container: #293e98ff;
$color-theme-sys-light-tertiary: #00809bff;
$color-theme-sys-light-on-tertiary: #ffffffff;
$color-theme-sys-light-tertiary-container: #5ad5f9ff;
$color-theme-sys-light-on-tertiary-container: #004e5fff;
$color-theme-sys-light-error: #af2e30ff;
$color-theme-sys-light-on-error: #ffffffff;
$color-theme-sys-light-error-container: #ffb3aeff;
$color-theme-sys-light-on-error-container: #8d131bff;
$color-theme-sys-light-success: #008859ff;
$color-theme-sys-light-on-success: #ffffffff;
$color-theme-sys-light-success-container: #5bdda0ff;
$color-theme-sys-light-on-success-container: #005234ff;
$color-theme-sys-light-warning: #857100ff;
$color-theme-sys-light-on-warning: #ffffffff;
$color-theme-sys-light-warning-container: #fee264ff;
$color-theme-sys-light-on-warning-container: #221b00ff;
$color-theme-sys-light-info: #00829dff;
$color-theme-sys-light-on-info: #ffffffff;
$color-theme-sys-light-info-container: #5ad5f9ff;
$color-theme-sys-light-on-info-container: #004e5fff;
$color-theme-sys-light-outline: #757874ff;
$color-theme-sys-light-background: #ffffffff;
$color-theme-sys-light-on-background: #191c1aff;
$color-theme-sys-light-surface: #ffffffff;
$color-theme-sys-light-on-surface: #191c1aff;
$color-theme-sys-light-surface-variant: #c5c7c3ff;
$color-theme-sys-light-on-surface-variant: #444845ff;
$color-theme-sys-light-inverse-surface: #2e312eff;
$color-theme-sys-light-inverse-on-surface: #eff1edff;
$color-theme-sys-light-inverse-primary: #5bdda0ff;
$color-theme-sys-light-inverse-secondary: #b9c3ffff;
$color-theme-sys-light-inverse-tertiary: #5ad5f9ff;
$color-theme-sys-light-shadow: #000000ff;
$color-theme-sys-light-surface-tint: #006c46ff;
$color-theme-sys-light-outline-variant: #c0c9c1ff;
$color-theme-sys-light-scrim: #000000ff;
$color-theme-sys-light-focus-a11y: #266eb5ff;
$color-theme-sys-dark-primary: #5bdda0ff;
$color-theme-sys-dark-on-primary: #003822ff;
$color-theme-sys-dark-primary-container: #006c46ff;
$color-theme-sys-dark-on-primary-container: #7afabaff;
$color-theme-sys-dark-secondary: #94a6ffff;
$color-theme-sys-dark-on-secondary: #092582ff;
$color-theme-sys-dark-secondary-container: #4357b2ff;
$color-theme-sys-dark-on-secondary-container: #dde1ffff;
$color-theme-sys-dark-tertiary: #5ad5f9ff;
$color-theme-sys-dark-on-tertiary: #003642ff;
$color-theme-sys-dark-tertiary-container: #00677dff;
$color-theme-sys-dark-on-tertiary-container: #b3ebffff;
$color-theme-sys-dark-error: #ffb3aeff;
$color-theme-sys-dark-on-error: #68000cff;
$color-theme-sys-dark-error-container: #8d131bff;
$color-theme-sys-dark-on-error-container: #ffdad7ff;
$color-theme-sys-dark-success: #5bdda0ff;
$color-theme-sys-dark-on-success: #003822ff;
$color-theme-sys-dark-success-container: #006c46ff;
$color-theme-sys-dark-on-success-container: #7afabaff;
$color-theme-sys-dark-warning: #e1c64bff;
$color-theme-sys-dark-on-warning: #3a3000ff;
$color-theme-sys-dark-warning-container: #6e5d00ff;
$color-theme-sys-dark-on-warning-container: #fee264ff;
$color-theme-sys-dark-info: #5ad5f9ff;
$color-theme-sys-dark-on-info: #003642ff;
$color-theme-sys-dark-info-container: #00677dff;
$color-theme-sys-dark-on-info-container: #b3ebffff;
$color-theme-sys-dark-outline: #8f918eff;
$color-theme-sys-dark-background: #191c1aff;
$color-theme-sys-dark-on-background: #e1e3dfff;
$color-theme-sys-dark-surface: #000000ff;
$color-theme-sys-dark-on-surface: #c5c7c3ff;
$color-theme-sys-dark-surface-variant: #444845ff;
$color-theme-sys-dark-on-surface-variant: #c5c7c3ff;
$color-theme-sys-dark-inverse-surface: #e1e3dfff;
$color-theme-sys-dark-inverse-on-surface: #191c1aff;
$color-theme-sys-dark-inverse-primary: #006c46ff;
$color-theme-sys-dark-inverse-secondary: #4357b2ff;
$color-theme-sys-dark-inverse-tertiary: #00677dff;
$color-theme-sys-dark-shadow: #000000ff;
$color-theme-sys-dark-surface-tint: #5bdda0ff;
$color-theme-sys-dark-outline-variant: #404943ff;
$color-theme-sys-dark-scrim: #000000ff;
$color-theme-sys-dark-focus-a11y: #4fa8ffff;
$color-theme-ref-primary-primary0: #000000ff;
$color-theme-ref-primary-primary5: #00150aff;
$color-theme-ref-primary-primary10: #002112ff;
$color-theme-ref-primary-primary20: #003822ff;
$color-theme-ref-primary-primary25: #00452bff;
$color-theme-ref-primary-primary35: #005f3dff;
$color-theme-ref-primary-primary30: #005234ff;
$color-theme-ref-primary-primary40: #006c46ff;
$color-theme-ref-primary-primary50: #008859ff;
$color-theme-ref-primary-primary60: #00a56dff;
$color-theme-ref-primary-primary70: #3ac186ff;
$color-theme-ref-primary-primary80: #5bdda0ff;
$color-theme-ref-primary-primary90: #7afabaff;
$color-theme-ref-primary-primary95: #bfffd9ff;
$color-theme-ref-primary-primary98: #e8ffeeff;
$color-theme-ref-primary-primary99: #f4fff5ff;
$color-theme-ref-primary-primary100: #ffffffff;
$color-theme-ref-secondary-secondary0: #000000ff;
$color-theme-ref-secondary-secondary5: #000a3cff;
$color-theme-ref-secondary-secondary10: #001356ff;
$color-theme-ref-secondary-secondary20: #092582ff;
$color-theme-ref-secondary-secondary25: #1a328dff;
$color-theme-ref-secondary-secondary30: #293e98ff;
$color-theme-ref-secondary-secondary35: #364ba5ff;
$color-theme-ref-secondary-secondary40: #4357b2ff;
$color-theme-ref-secondary-secondary50: #5c71cdff;
$color-theme-ref-secondary-secondary60: #778be9ff;
$color-theme-ref-secondary-secondary70: #94a6ffff;
$color-theme-ref-secondary-secondary80: #b9c3ffff;
$color-theme-ref-secondary-secondary90: #dde1ffff;
$color-theme-ref-secondary-secondary95: #f0efffff;
$color-theme-ref-secondary-secondary98: #fbf8ffff;
$color-theme-ref-secondary-secondary99: #fefbffff;
$color-theme-ref-secondary-secondary100: #ffffffff;
$color-theme-ref-tertiary-tertiary0: #000000ff;
$color-theme-ref-tertiary-tertiary5: #00131aff;
$color-theme-ref-tertiary-tertiary10: #001f27ff;
$color-theme-ref-tertiary-tertiary20: #003642ff;
$color-theme-ref-tertiary-tertiary25: #004150ff;
$color-theme-ref-tertiary-tertiary30: #004e5fff;
$color-theme-ref-tertiary-tertiary35: #005a6eff;
$color-theme-ref-tertiary-tertiary40: #00677dff;
$color-theme-ref-tertiary-tertiary50: #00809bff;
$color-theme-ref-tertiary-tertiary60: #009ebeff;
$color-theme-ref-tertiary-tertiary70: #34b9dcff;
$color-theme-ref-tertiary-tertiary80: #5ad5f9ff;
$color-theme-ref-tertiary-tertiary90: #b3ebffff;
$color-theme-ref-tertiary-tertiary95: #dbf5ffff;
$color-theme-ref-tertiary-tertiary98: #f2fbffff;
$color-theme-ref-tertiary-tertiary99: #f9fdffff;
$color-theme-ref-tertiary-tertiary100: #ffffffff;
$color-theme-ref-error-error0: #000000ff;
$color-theme-ref-error-error5: #2d0002ff;
$color-theme-ref-error-error10: #410005ff;
$color-theme-ref-error-error20: #68000cff;
$color-theme-ref-error-error25: #7c0312ff;
$color-theme-ref-error-error30: #8d131bff;
$color-theme-ref-error-error35: #9e2126ff;
$color-theme-ref-error-error40: #af2e30ff;
$color-theme-ref-error-error50: #d14746ff;
$color-theme-ref-error-error60: #f3605dff;
$color-theme-ref-error-error70: #ff8983ff;
$color-theme-ref-error-error80: #ffb3aeff;
$color-theme-ref-error-error90: #ffdad7ff;
$color-theme-ref-error-error95: #ffedebff;
$color-theme-ref-error-error98: #fff8f7ff;
$color-theme-ref-error-error99: #fffbffff;
$color-theme-ref-error-error100: #ffffffff;
$color-theme-ref-warning-warning0: #000000ff;
$color-theme-ref-warning-warning5: #151100ff;
$color-theme-ref-warning-warning10: #221b00ff;
$color-theme-ref-warning-warning15: #2d2500ff;
$color-theme-ref-warning-warning20: #3a3000ff;
$color-theme-ref-warning-warning25: #463b00ff;
$color-theme-ref-warning-warning30: #534600ff;
$color-theme-ref-warning-warning35: #615200ff;
$color-theme-ref-warning-warning40: #6e5d00ff;
$color-theme-ref-warning-warning50: #857100ff;
$color-theme-ref-warning-warning60: #a79014ff;
$color-theme-ref-warning-warning70: #c4aa32ff;
$color-theme-ref-warning-warning80: #e1c64bff;
$color-theme-ref-warning-warning90: #fee264ff;
$color-theme-ref-warning-warning95: #fff0bfff;
$color-theme-ref-warning-warning98: #fff9eeff;
$color-theme-ref-warning-warning99: #fffbffff;
$color-theme-ref-warning-warning100: #ffffffff;
$color-theme-ref-success-success0: #000000ff;
$color-theme-ref-success-success5: #00150aff;
$color-theme-ref-success-success10: #002112ff;
$color-theme-ref-success-success15: #002c1aff;
$color-theme-ref-success-success20: #003822ff;
$color-theme-ref-success-success25: #00452bff;
$color-theme-ref-success-success30: #005234ff;
$color-theme-ref-success-success35: #005f3dff;
$color-theme-ref-success-success40: #006c46ff;
$color-theme-ref-success-success50: #008859ff;
$color-theme-ref-success-success60: #00a56dff;
$color-theme-ref-success-success70: #3ac186ff;
$color-theme-ref-success-success80: #5bdda0ff;
$color-theme-ref-success-success90: #7afabaff;
$color-theme-ref-success-success95: #bfffd9ff;
$color-theme-ref-success-success98: #e8ffeeff;
$color-theme-ref-success-success99: #f4fff5ff;
$color-theme-ref-success-success100: #ffffffff;
$color-theme-ref-neutral-neutral0: #000000ff;
$color-theme-ref-neutral-neutral5: #0e120fff;
$color-theme-ref-neutral-neutral10: #191c1aff;
$color-theme-ref-neutral-neutral20: #2e312eff;
$color-theme-ref-neutral-neutral25: #393c39ff;
$color-theme-ref-neutral-neutral30: #444845ff;
$color-theme-ref-neutral-neutral35: #505350ff;
$color-theme-ref-neutral-neutral40: #5c5f5cff;
$color-theme-ref-neutral-neutral50: #757874ff;
$color-theme-ref-neutral-neutral60: #8f918eff;
$color-theme-ref-neutral-neutral70: #a9aca8ff;
$color-theme-ref-neutral-neutral80: #c5c7c3ff;
$color-theme-ref-neutral-neutral90: #e1e3dfff;
$color-theme-ref-neutral-neutral95: #eff1edff;
$color-theme-ref-neutral-neutral98: #f8faf5ff;
$color-theme-ref-neutral-neutral99: #fbfdf8ff;
$color-theme-ref-neutral-neutral100: #ffffffff;
$color-theme-ref-neutral-variant-neutral-variant0: #000000ff;
$color-theme-ref-neutral-variant-neutral-variant5: #0b130eff;
$color-theme-ref-neutral-variant-neutral-variant10: #151d18ff;
$color-theme-ref-neutral-variant-neutral-variant20: #2a322dff;
$color-theme-ref-neutral-variant-neutral-variant25: #353d38ff;
$color-theme-ref-neutral-variant-neutral-variant30: #404943ff;
$color-theme-ref-neutral-variant-neutral-variant35: #4c544eff;
$color-theme-ref-neutral-variant-neutral-variant40: #58605aff;
$color-theme-ref-neutral-variant-neutral-variant50: #707972ff;
$color-theme-ref-neutral-variant-neutral-variant60: #8a938cff;
$color-theme-ref-neutral-variant-neutral-variant70: #a4ada6ff;
$color-theme-ref-neutral-variant-neutral-variant80: #c0c9c1ff;
$color-theme-ref-neutral-variant-neutral-variant90: #dce5dcff;
$color-theme-ref-neutral-variant-neutral-variant95: #eaf3eaff;
$color-theme-ref-neutral-variant-neutral-variant98: #f3fcf3ff;
$color-theme-ref-neutral-variant-neutral-variant99: #f6fff6ff;
$color-theme-ref-neutral-variant-neutral-variant100: #ffffffff;
$color-theme-transparent-light-96: #fefefef5;
$color-theme-transparent-light-88: #fefefee0;
$color-theme-transparent-light-80: #fefefecc;
$color-theme-transparent-light-72: #fefefeb8;
$color-theme-transparent-light-64: #fefefea3;
$color-theme-transparent-light-56: #fefefe8f;
$color-theme-transparent-light-48: #fefefe7a;
$color-theme-transparent-light-40: #fefefe66;
$color-theme-transparent-light-32: #fefefe52;
$color-theme-transparent-light-24: #fefefe3d;
$color-theme-transparent-light-16: #fefefe29;
$color-theme-transparent-light-8: #fefefe14;
$color-theme-transparent-light-0: #fefefe00;
$color-theme-transparent-dark-96: #020917f5;
$color-theme-transparent-dark-88: #020917e0;
$color-theme-transparent-dark-80: #020917cc;
$color-theme-transparent-dark-72: #020917b8;
$color-theme-transparent-dark-64: #020917a3;
$color-theme-transparent-dark-56: #0209178f;
$color-theme-transparent-dark-48: #0209177a;
$color-theme-transparent-dark-40: #02091766;
$color-theme-transparent-dark-32: #02091752;
$color-theme-transparent-dark-24: #0209173d;
$color-theme-transparent-dark-16: #02091729;
$color-theme-transparent-dark-8: #02091714;
$color-theme-transparent-dark-0: #02091700;
$color-theme-key-colors-primary: #51d497ff;
$color-theme-key-colors-secondary: #8397f6ff;
$color-theme-key-colors-tertiary: #4ca8c3ff;
$color-theme-surfaces-light-surface-hover: #fefefecc;
$color-theme-surfaces-light-surface-active: #fefefef5;
$color-theme-surfaces-dark-surface-hover: #000000db;
$color-theme-surfaces-dark-surface-active: #000000f5;
$color-theme-surfaces-gradient-bg-dark: #1d3557ff;
$color-theme-state-layers-light-primary-opacity-0-08: #006c4614;
$color-theme-state-layers-light-primary-opacity-0-12: #006c461f;
$color-theme-state-layers-light-primary-opacity-0-16: #006c4629;
$color-theme-state-layers-light-on-primary-opacity-0-08: #ffffff14;
$color-theme-state-layers-light-on-primary-opacity-0-12: #ffffff1f;
$color-theme-state-layers-light-on-primary-opacity-0-16: #ffffff29;
$color-theme-state-layers-light-primary-container-opacity-0-08: #7afaba14;
$color-theme-state-layers-light-primary-container-opacity-0-12: #7afaba1f;
$color-theme-state-layers-light-primary-container-opacity-0-16: #7afaba29;
$color-theme-state-layers-light-on-primary-container-opacity-0-08: #00211214;
$color-theme-state-layers-light-on-primary-container-opacity-0-12: #0021121f;
$color-theme-state-layers-light-on-primary-container-opacity-0-16: #00211229;
$color-theme-state-layers-light-secondary-opacity-0-08: #4357b214;
$color-theme-state-layers-light-secondary-opacity-0-12: #4357b21f;
$color-theme-state-layers-light-secondary-opacity-0-16: #4357b229;
$color-theme-state-layers-light-on-secondary-opacity-0-08: #ffffff14;
$color-theme-state-layers-light-on-secondary-opacity-0-12: #ffffff1f;
$color-theme-state-layers-light-on-secondary-opacity-0-16: #ffffff29;
$color-theme-state-layers-light-secondary-container-opacity-0-08: #dde1ff14;
$color-theme-state-layers-light-secondary-container-opacity-0-12: #dde1ff1f;
$color-theme-state-layers-light-secondary-container-opacity-0-16: #dde1ff29;
$color-theme-state-layers-light-on-secondary-container-opacity-0-08: #00135614;
$color-theme-state-layers-light-on-secondary-container-opacity-0-12: #0013561f;
$color-theme-state-layers-light-on-secondary-container-opacity-0-16: #00135629;
$color-theme-state-layers-light-tertiary-opacity-0-08: #00677d14;
$color-theme-state-layers-light-tertiary-opacity-0-12: #00677d1f;
$color-theme-state-layers-light-tertiary-opacity-0-16: #00677d29;
$color-theme-state-layers-light-on-tertiary-opacity-0-08: #ffffff14;
$color-theme-state-layers-light-on-tertiary-opacity-0-12: #ffffff1f;
$color-theme-state-layers-light-on-tertiary-opacity-0-16: #ffffff29;
$color-theme-state-layers-light-tertiary-container-opacity-0-08: #b3ebff14;
$color-theme-state-layers-light-tertiary-container-opacity-0-12: #b3ebff1f;
$color-theme-state-layers-light-tertiary-container-opacity-0-16: #b3ebff29;
$color-theme-state-layers-light-on-tertiary-container-opacity-0-08: #001f2714;
$color-theme-state-layers-light-on-tertiary-container-opacity-0-12: #001f271f;
$color-theme-state-layers-light-on-tertiary-container-opacity-0-16: #001f2729;
$color-theme-state-layers-light-error-opacity-0-08: #af2e3014;
$color-theme-state-layers-light-error-opacity-0-12: #af2e301f;
$color-theme-state-layers-light-error-opacity-0-16: #af2e3029;
$color-theme-state-layers-light-on-error-opacity-0-08: #ffffff14;
$color-theme-state-layers-light-on-error-opacity-0-12: #ffffff1f;
$color-theme-state-layers-light-on-error-opacity-0-16: #ffffff29;
$color-theme-state-layers-light-error-container-opacity-0-08: #ffdad714;
$color-theme-state-layers-light-error-container-opacity-0-12: #ffdad71f;
$color-theme-state-layers-light-error-container-opacity-0-16: #ffdad729;
$color-theme-state-layers-light-on-error-container-opacity-0-08: #41000514;
$color-theme-state-layers-light-on-error-container-opacity-0-12: #4100051f;
$color-theme-state-layers-light-on-error-container-opacity-0-16: #41000529;
$color-theme-state-layers-light-outline-opacity-0-08: #70797214;
$color-theme-state-layers-light-outline-opacity-0-12: #7079721f;
$color-theme-state-layers-light-outline-opacity-0-16: #70797229;
$color-theme-state-layers-light-background-opacity-0-08: #fbfdf814;
$color-theme-state-layers-light-background-opacity-0-12: #fbfdf81f;
$color-theme-state-layers-light-background-opacity-0-16: #fbfdf829;
$color-theme-state-layers-light-on-background-opacity-0-08: #191c1a14;
$color-theme-state-layers-light-on-background-opacity-0-12: #191c1a1f;
$color-theme-state-layers-light-on-background-opacity-0-16: #191c1a29;
$color-theme-state-layers-light-surface-opacity-0-08: #fbfdf814;
$color-theme-state-layers-light-surface-opacity-0-12: #fbfdf81f;
$color-theme-state-layers-light-surface-opacity-0-16: #fbfdf829;
$color-theme-state-layers-light-on-surface-opacity-0-08: #191c1a14;
$color-theme-state-layers-light-on-surface-opacity-0-12: #191c1a1f;
$color-theme-state-layers-light-on-surface-opacity-0-16: #191c1a29;
$color-theme-state-layers-light-surface-variant-opacity-0-08: #dce5dc14;
$color-theme-state-layers-light-surface-variant-opacity-0-12: #dce5dc1f;
$color-theme-state-layers-light-surface-variant-opacity-0-16: #dce5dc29;
$color-theme-state-layers-light-on-surface-variant-opacity-0-08: #40494314;
$color-theme-state-layers-light-on-surface-variant-opacity-0-12: #4049431f;
$color-theme-state-layers-light-on-surface-variant-opacity-0-16: #40494329;
$color-theme-state-layers-light-inverse-surface-opacity-0-08: #2e312e14;
$color-theme-state-layers-light-inverse-surface-opacity-0-12: #2e312e1f;
$color-theme-state-layers-light-inverse-surface-opacity-0-16: #2e312e29;
$color-theme-state-layers-light-inverse-on-surface-opacity-0-08: #eff1ed14;
$color-theme-state-layers-light-inverse-on-surface-opacity-0-12: #eff1ed1f;
$color-theme-state-layers-light-inverse-on-surface-opacity-0-16: #eff1ed29;
$color-theme-state-layers-light-inverse-primary-opacity-0-08: #5bdda014;
$color-theme-state-layers-light-inverse-primary-opacity-0-12: #5bdda01f;
$color-theme-state-layers-light-inverse-primary-opacity-0-16: #5bdda029;
$color-theme-state-layers-light-shadow-opacity-0-08: #00000014;
$color-theme-state-layers-light-shadow-opacity-0-12: #0000001f;
$color-theme-state-layers-light-shadow-opacity-0-16: #00000029;
$color-theme-state-layers-light-surface-tint-opacity-0-08: #006c4614;
$color-theme-state-layers-light-surface-tint-opacity-0-12: #006c461f;
$color-theme-state-layers-light-surface-tint-opacity-0-16: #006c4629;
$color-theme-state-layers-light-outline-variant-opacity-0-08: #c0c9c114;
$color-theme-state-layers-light-outline-variant-opacity-0-12: #c0c9c11f;
$color-theme-state-layers-light-outline-variant-opacity-0-16: #c0c9c129;
$color-theme-state-layers-light-scrim-opacity-0-08: #00000014;
$color-theme-state-layers-light-scrim-opacity-0-12: #0000001f;
$color-theme-state-layers-light-scrim-opacity-0-16: #00000029;
$color-theme-state-layers-dark-primary-opacity-0-08: #5bdda014;
$color-theme-state-layers-dark-primary-opacity-0-12: #5bdda01f;
$color-theme-state-layers-dark-primary-opacity-0-16: #5bdda029;
$color-theme-state-layers-dark-on-primary-opacity-0-08: #00382214;
$color-theme-state-layers-dark-on-primary-opacity-0-12: #0038221f;
$color-theme-state-layers-dark-on-primary-opacity-0-16: #00382229;
$color-theme-state-layers-dark-primary-container-opacity-0-08: #00523414;
$color-theme-state-layers-dark-primary-container-opacity-0-12: #0052341f;
$color-theme-state-layers-dark-primary-container-opacity-0-16: #00523429;
$color-theme-state-layers-dark-on-primary-container-opacity-0-08: #7afaba14;
$color-theme-state-layers-dark-on-primary-container-opacity-0-12: #7afaba1f;
$color-theme-state-layers-dark-on-primary-container-opacity-0-16: #7afaba29;
$color-theme-state-layers-dark-secondary-opacity-0-08: #b9c3ff14;
$color-theme-state-layers-dark-secondary-opacity-0-12: #b9c3ff1f;
$color-theme-state-layers-dark-secondary-opacity-0-16: #b9c3ff29;
$color-theme-state-layers-dark-on-secondary-opacity-0-08: #09258214;
$color-theme-state-layers-dark-on-secondary-opacity-0-12: #0925821f;
$color-theme-state-layers-dark-on-secondary-opacity-0-16: #09258229;
$color-theme-state-layers-dark-secondary-container-opacity-0-08: #293e9814;
$color-theme-state-layers-dark-secondary-container-opacity-0-12: #293e981f;
$color-theme-state-layers-dark-secondary-container-opacity-0-16: #293e9829;
$color-theme-state-layers-dark-on-secondary-container-opacity-0-08: #dde1ff14;
$color-theme-state-layers-dark-on-secondary-container-opacity-0-12: #dde1ff1f;
$color-theme-state-layers-dark-on-secondary-container-opacity-0-16: #dde1ff29;
$color-theme-state-layers-dark-tertiary-opacity-0-08: #5ad5f914;
$color-theme-state-layers-dark-tertiary-opacity-0-12: #5ad5f91f;
$color-theme-state-layers-dark-tertiary-opacity-0-16: #5ad5f929;
$color-theme-state-layers-dark-on-tertiary-opacity-0-08: #00364214;
$color-theme-state-layers-dark-on-tertiary-opacity-0-12: #0036421f;
$color-theme-state-layers-dark-on-tertiary-opacity-0-16: #00364229;
$color-theme-state-layers-dark-tertiary-container-opacity-0-08: #004e5f14;
$color-theme-state-layers-dark-tertiary-container-opacity-0-12: #004e5f1f;
$color-theme-state-layers-dark-tertiary-container-opacity-0-16: #004e5f29;
$color-theme-state-layers-dark-on-tertiary-container-opacity-0-08: #b3ebff14;
$color-theme-state-layers-dark-on-tertiary-container-opacity-0-12: #b3ebff1f;
$color-theme-state-layers-dark-on-tertiary-container-opacity-0-16: #b3ebff29;
$color-theme-state-layers-dark-error-opacity-0-08: #ffb3ae14;
$color-theme-state-layers-dark-error-opacity-0-12: #ffb3ae1f;
$color-theme-state-layers-dark-error-opacity-0-16: #ffb3ae29;
$color-theme-state-layers-dark-on-error-opacity-0-08: #68000c14;
$color-theme-state-layers-dark-on-error-opacity-0-12: #68000c1f;
$color-theme-state-layers-dark-on-error-opacity-0-16: #68000c29;
$color-theme-state-layers-dark-error-container-opacity-0-08: #8d131b14;
$color-theme-state-layers-dark-error-container-opacity-0-12: #8d131b1f;
$color-theme-state-layers-dark-error-container-opacity-0-16: #8d131b29;
$color-theme-state-layers-dark-on-error-container-opacity-0-08: #ffdad714;
$color-theme-state-layers-dark-on-error-container-opacity-0-12: #ffdad71f;
$color-theme-state-layers-dark-on-error-container-opacity-0-16: #ffdad729;
$color-theme-state-layers-dark-outline-opacity-0-08: #8a938c14;
$color-theme-state-layers-dark-outline-opacity-0-12: #8a938c1f;
$color-theme-state-layers-dark-outline-opacity-0-16: #8a938c29;
$color-theme-state-layers-dark-background-opacity-0-08: #191c1a14;
$color-theme-state-layers-dark-background-opacity-0-12: #191c1a1f;
$color-theme-state-layers-dark-background-opacity-0-16: #191c1a29;
$color-theme-state-layers-dark-on-background-opacity-0-08: #e1e3df14;
$color-theme-state-layers-dark-on-background-opacity-0-12: #e1e3df1f;
$color-theme-state-layers-dark-on-background-opacity-0-16: #e1e3df29;
$color-theme-state-layers-dark-surface-opacity-0-08: #191c1a14;
$color-theme-state-layers-dark-surface-opacity-0-12: #191c1a1f;
$color-theme-state-layers-dark-surface-opacity-0-16: #191c1a29;
$color-theme-state-layers-dark-on-surface-opacity-0-08: #c5c7c314;
$color-theme-state-layers-dark-on-surface-opacity-0-12: #c5c7c31f;
$color-theme-state-layers-dark-on-surface-opacity-0-16: #c5c7c329;
$color-theme-state-layers-dark-surface-variant-opacity-0-08: #40494314;
$color-theme-state-layers-dark-surface-variant-opacity-0-12: #4049431f;
$color-theme-state-layers-dark-surface-variant-opacity-0-16: #40494329;
$color-theme-state-layers-dark-on-surface-variant-opacity-0-08: #c0c9c114;
$color-theme-state-layers-dark-on-surface-variant-opacity-0-12: #c0c9c11f;
$color-theme-state-layers-dark-on-surface-variant-opacity-0-16: #c0c9c129;
$color-theme-state-layers-dark-inverse-surface-opacity-0-08: #e1e3df14;
$color-theme-state-layers-dark-inverse-surface-opacity-0-12: #e1e3df1f;
$color-theme-state-layers-dark-inverse-surface-opacity-0-16: #e1e3df29;
$color-theme-state-layers-dark-inverse-on-surface-opacity-0-08: #191c1a14;
$color-theme-state-layers-dark-inverse-on-surface-opacity-0-12: #191c1a1f;
$color-theme-state-layers-dark-inverse-on-surface-opacity-0-16: #191c1a29;
$color-theme-state-layers-dark-inverse-primary-opacity-0-08: #006c4614;
$color-theme-state-layers-dark-inverse-primary-opacity-0-12: #006c461f;
$color-theme-state-layers-dark-inverse-primary-opacity-0-16: #006c4629;
$color-theme-state-layers-dark-shadow-opacity-0-08: #00000014;
$color-theme-state-layers-dark-shadow-opacity-0-12: #0000001f;
$color-theme-state-layers-dark-shadow-opacity-0-16: #00000029;
$color-theme-state-layers-dark-surface-tint-opacity-0-08: #5bdda014;
$color-theme-state-layers-dark-surface-tint-opacity-0-12: #5bdda01f;
$color-theme-state-layers-dark-surface-tint-opacity-0-16: #5bdda029;
$color-theme-state-layers-dark-outline-variant-opacity-0-08: #40494314;
$color-theme-state-layers-dark-outline-variant-opacity-0-12: #4049431f;
$color-theme-state-layers-dark-outline-variant-opacity-0-16: #40494329;
$color-theme-state-layers-dark-scrim-opacity-0-08: #00000014;
$color-theme-state-layers-dark-scrim-opacity-0-12: #0000001f;
$color-theme-state-layers-dark-scrim-opacity-0-16: #00000029;
$gradient-theme-surfaces-light-gradient-main: linear-gradient(114.06deg, #fefefee6 30%, #fefefe4d 90%);
$gradient-theme-surfaces-dark-gradient-main: linear-gradient(114.06deg, #020917e6 0%, #0209174d 100%);
$gradient-theme-surfaces-gradient-primary-light: linear-gradient(101.47deg, #00a56dff 0%, #1d3557ff 100%);
$gradient-theme-surfaces-gradient-primary-dark: linear-gradient(101.47deg, #00a56dff 0%, #00677dff 100%);
$gradient-theme-surfaces-gradient-secondary-light-0: linear-gradient(159.55deg, #34b9dcff 0%, #5c71cdff 100%);
$gradient-theme-surfaces-gradient-secondary-light-1: linear-gradient(114.06deg, #00000059 0%, #00000026 100%);
$gradient-theme-surfaces-gradient-secondary-dark-0: linear-gradient(159.55deg, #34b9dcff 0%, #5c71cdff 100%);
$gradient-theme-surfaces-gradient-secondary-dark-1: linear-gradient(114.06deg, #00000059 0%, #00000026 100%);
$gradient-theme-surfaces-gradient-tertiary-light: linear-gradient(175.31deg, #5ad5f9ff 0%, #004e5fff 100%);
$gradient-theme-surfaces-gradient-tertiary-dark: linear-gradient(135.00deg, #5ad5f9ff 11%, #00677dff 100%);
$gradient-theme-surfaces-gradient-bg-light-0: linear-gradient(175.31deg, #efefefff 0%, #4ca8c3ff 100%);
$gradient-theme-surfaces-gradient-bg-light-1: #ffffff99;
$font-typography-display-large: normal 300 64px/96px 'Inter';
$font-typography-display-medium: normal 300 52px/78px 'Inter';
$font-typography-headline-h1: normal 300 48px/57.6px 'Inter';
$font-typography-headline-h2: normal 300 36px/43.2px 'Inter';
$font-typography-headline-h3: normal 600 28px/34.72px 'Inter';
$font-typography-title-subtitle-1: normal 600 24px/36px 'Inter';
$font-typography-title-subtitle-2: normal 600 20px/30px 'Inter';
$font-typography-title-subtitle-3: normal 400 20px/30px 'Inter';
$font-typography-title-subhead-4: normal 600 18px/27px 'Inter';
$font-typography-body-body-large: normal 400 18px/27px 'Inter';
$font-typography-body-body-medium: normal 400 16px/22.88px 'Inter';
$font-typography-body-body-medium-emphasis: normal 600 16px/22.88px 'Inter';
$font-typography-body-body-small: normal 400 14px/20.02px 'Inter';
$font-typography-body-body-small-emphasis: normal 600 14px/20.02px 'Inter';
$font-typography-button-button-medium: normal 700 14px/20px 'Inter';
$font-typography-button-button-large: normal 700 16px/24px 'Inter';
$font-typography-button-button-small: normal 600 12px/24px 'Inter';
$font-typography-label-lable-medium: normal 600 12px/14.4px 'Inter';
$font-typography-label-label-small: normal 600 10px/16.5px 'Inter';
$font-typography-label-lable-large: normal 400 14px/17.64px 'Inter';
$font-typography-label-overline-default: normal 600 12px/15.12px 'Inter';
$font-typography-inputs-input-label-helper-text-chip: normal 400 12px/12px 'Inter';
$font-typography-inputs-tooltip: normal 500 14px/24px 'Inter';
$font-typography-inputs-input-text: normal 400 16px/24px 'Inter';
$font-typography-inputs-avatar-initials: normal 500 14px/20px 'Inter';
$font-typography-inputs-table-header-id: normal 500 14px/24px 'Inter';
$font-typography-palette-math: normal 400 18px/27px 'Noto Sans Math';
$effect-drop-shadow: 0px 2px 4px 0px #00000040;
$effect-hover-shadow: 0px 0px 20px 0px #00000040;