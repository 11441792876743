@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Inter", helvetica, sans-serif;
}

body {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(137.13deg, #EFEFEF 0%, #4CA8C3 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover, cover;
  background-attachment: fixed;
  overflow-x: hidden;
}
body.in-ov2 {
  background-color: transparent;
  background-attachment: fixed;
  background-image: url(../images/glow.png);
  background-position: bottom right;
  background-size: cover;
}
body.in-ov2 .main-content {
  margin: 0;
}
body.in-ov2 .main-content .page-title {
  margin: 0;
}

.body-wrap {
  display: flex;
  min-height: 100vh;
}
.body-wrap .main-nav {
  flex: 0 0 6.75rem;
}
.body-wrap main {
  flex: 1;
  mix-blend-mode: normal;
  min-width: calc(100vw - 6.75rem);
}
.body-wrap main .wrapper {
  max-width: 64rem;
  margin: 2rem auto;
}

.app-header.MuiPaper-root {
  background: transparent;
}

.section-header {
  background: linear-gradient(114.06deg, rgba(0, 0, 0, 0.3490196078) 0%, rgba(0, 0, 0, 0.1490196078) 100%), linear-gradient(159.55deg, #34b9dc 0%, #5c71cd 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.section-header .search-assignments {
  display: flex;
  align-items: center;
  color: white;
  gap: 0.5rem;
}
.section-header .search-assignments select {
  background-color: white;
  border-radius: 22px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  min-width: 15rem;
  margin-right: 1rem;
  color: #020917;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}
.section-header .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.section-header .actions .MuiButton-root {
  border: 2px solid white;
  color: white;
}
.section-header .actions .MuiToggleButton-root {
  border: 0;
  border-radius: 50% !important;
}
.section-header .actions .MuiToggleButton-root.Mui-selected {
  background-color: rgba(254, 254, 254, 0.56);
  color: #293e98;
}

.main-content {
  margin: 2rem 8rem;
}

.fieldset-noborder {
  border: none;
  padding: 0;
  margin: 0;
}
.fieldset-noborder legend {
  margin-bottom: 0.5rem;
}

.radio-container {
  padding: 24px 0;
}
.radio-container:first-child {
  padding-top: 0;
}
.radio-container:last-child {
  padding-bottom: 0;
}
.radio-container .radio {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.875rem;
}
.radio-container .radio label {
  font: normal 600 14px/20.02px "Inter";
}
.radio-container p {
  font: normal 400 14px/20.02px "Inter";
  color: #585858;
  margin-left: 2.25rem;
}
.radio-container:first-of-type {
  border-bottom: 1px solid #c0c9c1;
}
.radio-container:not(:first-of-type) {
  border-bottom: 1px solid #c0c9c1;
}
.radio-container:last-of-type {
  border-bottom: none;
}
.radio-container .input-container {
  margin-left: 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.form-element label {
  font: normal 400 12px/12px "Inter";
  color: #585858;
}

/* Select ---*/
.select-container {
  position: relative;
}
.select-container svg {
  position: absolute;
  right: 10px;
  top: calc(50% - 15px);
  fill: #585858;
}
.select-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  margin: 4px 0;
  padding: 0 42px 0 12px;
  height: 36px;
  color: #020917;
  border: 1px solid #757874;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}

.old-page {
  background-color: #fff;
  min-height: calc(100vh - 70px);
  padding: 1rem 2rem;
}
.old-page.full-page {
  min-height: 100vh;
}

.header {
  display: flex;
  width: 1440px;
  padding: 28px 36px;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}
.sidebar .header-logo {
  margin-bottom: 3rem;
}
.sidebar .main-menu .MuiMenuItem-root {
  padding: 0;
  border-radius: 50%;
  max-height: 3rem;
  margin: 1.5rem 0;
}
.sidebar .main-menu .MuiLink-root {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-indent: -9999px;
  background-color: rgba(2, 9, 23, 0.32);
}
.sidebar .main-menu .Mui-selected .MuiLink-root {
  background-color: #5bdda0;
}

/* Switch/Toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #a9aca8 url(../images/icon_cross.png) no-repeat 28px center;
  transition: 0.4s;
  border-radius: 34px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  top: 3px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background: #5066c5 url(../images/icon_check.png) no-repeat 9px center;
}
.switch input:checked + .slider:before {
  transform: translateX(24px);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #047A9C;
}
.switch input:disabled + .slider, .switch input[disabled] + .slider {
  background-color: #C2C7CC;
}

.options-menu {
  position: relative;
}
.options-menu .gr-dropdown-container .option-text {
  display: inline-flex;
  margin: 4px 16px;
  width: 100%;
}
.options-menu .gr-dropdown-container .option-text.has-icon {
  margin-left: 40px !important;
}
.options-menu .gr-btn {
  width: 38px;
  height: 38px;
}
.options-menu .dropdown-menu {
  top: calc(100% + 8px);
  right: 9px;
}
.options-menu .menu {
  top: 0;
  overflow: auto;
}

.assignment-metadata {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.assignment-row {
  cursor: pointer;
}
.assignment-row:hover {
  background: rgba(254, 254, 254, 0.8);
}

.create-assignment-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  grid-gap: 0.5rem;
  align-items: flex-end;
  margin: 1rem 0;
}
.create-assignment-header .assignment-name-block {
  grid-column: 1/span 3 !important;
  grid-row: 1;
  padding: 0;
  margin: 0;
}
.create-assignment-header .assignment-cart {
  grid-column: 5/span 4;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}

.create-assignment {
  padding: 2rem 5rem;
}
.create-assignment header {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.create-assignment header .date-icon {
  width: 60px;
  height: 60px;
  background-color: #e9e9e9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 36px;
}
.create-assignment header .date-icon.date-added {
  background-color: #1E496C;
  color: #fff;
  flex-direction: column;
}
.create-assignment header .date-icon svg {
  fill: #6a7070;
}
.create-assignment header .date-icon .month {
  font-size: 12px;
  display: block;
  text-transform: uppercase;
}
.create-assignment header .date-icon .day {
  font-size: 18px;
  display: block;
}
.create-assignment header .settings-help {
  align-self: end;
  justify-self: end;
  display: flex;
  align-items: center;
}
.create-assignment header .settings-help .gr-btn {
  margin: 0 9px;
}
.create-assignment header .settings-help .gr-btn.link-btn {
  color: #252525;
}
.create-assignment header .settings-help .gr-btn.link-btn svg {
  fill: #252525;
}
.create-assignment header h2 {
  color: #252525;
  font-size: 24px;
  font-weight: 400;
  margin: 0 !important;
  line-height: 1;
}
.create-assignment header .assignment-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-assignment header .assignment-actions .tag-homework {
  font-size: 14px;
  margin-right: 3px;
  padding: 3px 3px 3px 0;
}
.create-assignment header .assignment-actions .tag-homework:before {
  margin-right: 8px;
}
.create-assignment header .assignment-actions .left {
  display: flex;
  align-items: center;
}
.create-assignment header .assignment-actions .left .due-date {
  padding: 3px 16px;
  display: flex;
  align-items: center;
}
.create-assignment header .assignment-actions .left .due-date .MuiFormControl-root {
  margin: 0;
}
.create-assignment header .assignment-actions .left .gr-label {
  display: inline-block;
  margin-right: 3px;
  font-size: 14px;
  font-weight: 600;
  color: #3E4C59;
}
.create-assignment header .assignment-actions .link-btn {
  padding: 3px 6px;
}
.create-assignment header .edit-name-form {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  margin: 0.75rem 0 0.25rem;
  padding-right: 0.5rem;
}
.link-btn {
  border: 0;
  padding: 6px;
  min-width: 0;
  font-size: 14px;
  color: #047A9C;
  margin: 0 6px;
}
.link-btn svg {
  fill: #047A9C;
  margin-right: 8px;
}
.link-btn:disabled {
  background-color: transparent !important;
  color: #909090 !important;
}
.link-btn:disabled svg {
  fill: #909090 !important;
}
.link-btn:hover, .link-btn:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.assignment-manager-list-view.review-table {
  box-shadow: none;
  background-color: #fff;
}
.assignment-manager-list-view.review-table .clone-table .gr-checkbox label {
  text-indent: -9999px;
}
.assignment-manager-list-view .review-list-header {
  display: grid;
  grid-template-columns: 70px auto;
  grid-column-gap: 12px;
  background: #f5f5f5;
  border-top: 1px solid #E4E7EB;
  border-bottom: 1px solid #E4E7EB;
  height: 50px;
}
.assignment-manager-list-view .review-list-header .header-group {
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  grid-gap: 12px;
}
.assignment-manager-list-view .review-list-header .header-group .sortableColumn {
  padding: 0;
}
.assignment-manager-list-view .review-list-header .header-group .selections .gr-checkbox {
  margin: 0 10px 0 0;
}
.assignment-manager-list-view .review-list-header .header-group .name {
  grid-column: 3/span 9;
}
.assignment-manager-list-view .review-list-header .header-group .objective {
  grid-column: 12/span 8;
}
.assignment-manager-list-view .review-list-header .header-group .type {
  grid-column: 20/span 5;
}
.assignment-manager-list-view .review-list-header .header-group .time {
  grid-column: 25/span 4;
}

.search-filter-toggle .search-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #D9D9D9;
}
.search-filter-toggle .search-toggle .left, .search-filter-toggle .search-toggle .right {
  display: flex;
  align-items: center;
}
.search-filter-toggle .search-filter {
  border-top: 1px solid #c0c9c1;
}
.search-filter-toggle .search-filter .top-line {
  display: flex;
  padding: 1rem 0 0.5rem;
}
.search-filter-toggle .search-filter .form-element {
  flex: 1 1;
  margin-right: 12px;
}
.search-filter-toggle .search-filter .additional-filters {
  margin: 1.375rem 0 0 0;
  position: relative;
  flex: 1;
}
.search-filter-toggle .search-filter .additional-filters svg {
  position: absolute;
  right: 10px;
  top: calc(50% - 15px);
  fill: #585858;
}
.search-filter-toggle .search-filter .additional-filters .filter-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  margin: 4px 0;
  padding: 0 42px 0 12px;
  height: 36px;
  color: #020917;
  border: 1px solid #757874;
  background: white;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  text-align: left;
}
.search-filter-toggle .search-filter .additional-filters .backdrop {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.section-list h4 {
  margin: 0;
  padding-left: 18px;
  border-bottom: 1px solid #E4E7EB;
}
.section-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.section-list ul li h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  padding: 0 18px;
  background: #FFFFFF;
  border-bottom: 1px solid #c2c7cc;
}
.section-list ul li h5:hover {
  background: rgba(231, 232, 236, 0.5);
}
.section-list ul li h5 .sortableColumn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}
.section-list ul li h5 .sortableColumn:focus, .section-list ul li h5 .sortableColumn:active {
  outline: none;
  box-shadow: none;
}
.section-list ul li h5 .sortableColumn em {
  font-style: normal;
  color: #616E7C;
  font-size: 12px;
}
.section-list ul li.open h5 {
  background-color: #F2FCFF;
}
.section-list ul li.open h5 .sortableColumn .circle {
  background-color: #0189B0;
}
.section-list ul li.open h5 .sortableColumn svg {
  fill: #fff;
}
.section-list ul li .question-table {
  border-collapse: collapse;
  width: 100%;
}
.section-list ul li .question-table th {
  background: linear-gradient(180deg, #F2F3F7 0%, rgba(230, 231, 236, 0) 12.57%), #F7F8FC;
  border-bottom: 1px solid #CBD2D9;
  padding: 0 18px;
}
.section-list ul li .question-table td {
  padding: 0 18px;
  border-bottom: 1px solid #CBD2D9;
  vertical-align: middle;
  height: 64px;
}
.section-list ul li .question-table td .difficulty {
  display: inline-block;
  margin: 0 auto;
}
.section-list ul li .question-table th.text-center, .section-list ul li .question-table td.text-center {
  text-align: center;
}
.section-list ul li .question-table th.text-center .sortableColumn, .section-list ul li .question-table td.text-center .sortableColumn {
  margin: 0 auto;
}
.section-list ul li .question-table .add-item {
  width: 40px;
  height: 40px;
  margin: 0;
  border-radius: 50%;
}
.section-list ul li .question-table .add-item svg {
  border-radius: 50%;
  border: 2px solid #007FA3;
  fill: #007FA3;
}
.section-list ul li .question-table .add-item:focus, .section-list ul li .question-table .add-item:active {
  box-shadow: none;
  outline: 0;
}
.section-list ul li .question-table .add-item:hover {
  background-color: rgba(0, 127, 163, 0.08);
}
.section-list ul li .question-table .add-item:hover svg {
  fill: #fff;
  background-color: #007FA3;
}
.section-list ul li .question-table .add-item.selected {
  position: relative;
}
.section-list ul li .question-table .add-item.selected::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4caf50;
  position: absolute;
  top: 0;
  right: 0;
}
.section-list ul li .question-table .gr-checkbox {
  margin: 0;
}
.section-list ul li .question-table .gr-checkbox input[type=checkbox] {
  z-index: 2;
  width: 24px;
  height: 24px;
}
.section-list ul li .question-table .gr-checkbox input[type=checkbox] ~ label {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
.section-list ul li .question-table a {
  text-decoration: none;
}

.add-content-body {
  display: flex;
  gap: 1.5rem;
}
.add-content-body .assignment-creation-content {
  max-width: calc(100% - 300px);
}
.add-content-body.preview-closed .assignment-creation-content {
  flex: 1 0 100%;
  margin-right: 0;
  max-width: 100%;
}
.add-content-body.preview-closed .assignment-selections {
  border: 0;
  overflow: hidden;
}
.add-content-body.in-review .assignment-creation-content {
  overflow: hidden;
  border: 0;
  flex: 0 0 0;
}
.add-content-body.in-review .review-content {
  border: 1px solid #E4E7EB;
  height: calc(100vh - 325px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  animation: review 400ms ease-out;
  flex-shrink: 0;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
.add-content-body.in-review .review-content header {
  padding: 0 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 57px;
  border-bottom: 1px solid #E4E7EB;
}
.add-content-body.in-review .review-content header .gr-btn.secondary svg {
  fill: #047A9C;
}
.add-content-body.in-review .review-content header .gr-btn.secondary svg:last-child {
  margin: 0 0 0 10px;
}
.add-content-body.in-review .review-content header .gr-btn.secondary svg:first-child {
  margin: 0 10px 0 0;
}
.add-content-body.in-review .review-content .review-table-actions {
  flex: 0 0 46px;
  border-bottom: 1px solid #E4E7EB;
}
.add-content-body.in-review .review-content .review-table-actions .selection-summary {
  display: flex;
  justify-content: space-between;
}
.add-content-body.in-review .review-content .review-table-actions .selection-summary > div {
  flex: 1;
  padding: 0 20px;
  border-right: 1px solid #9AA5B1;
  white-space: nowrap;
}
.add-content-body.in-review .review-content .review-table-actions .selection-summary > div:last-child {
  text-align: right;
  border: 0;
  padding-right: 0;
}
.add-content-body.in-review .review-content .review-table-actions .selection-summary > div:first-child {
  padding-left: 0;
}
.add-content-body.in-review .review-content footer {
  background: linear-gradient(0deg, #FAFAFA, #FAFAFA);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex: 0 0 50px;
}
.add-content-body .empty-state {
  margin: 4rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding-bottom: 100px;
  color: #585858;
  font: normal 400 16px/22.88px "Inter";
}

.table-header {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.assignment-selections {
  border-radius: 0.5rem;
  grid-column: 2;
  width: 300px;
  background-color: white;
}
.assignment-selections header {
  display: flex;
  justify-content: space-between;
  grid-gap: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  align-items: center;
  padding: 4px 4px 0 4px;
  background: linear-gradient(114.06deg, rgba(0, 0, 0, 0.3490196078) 0%, rgba(0, 0, 0, 0.1490196078) 100%), linear-gradient(159.55deg, #34b9dc 0%, #5c71cd 100%);
}
.assignment-selections header * {
  color: white;
  fill: white;
}
.assignment-selections header h3 {
  margin: 0;
}
.assignment-selections .initial-text {
  text-align: center;
  max-width: 250px;
  margin: 70px auto;
}
.assignment-selections .initial-text p {
  color: #616E7C;
  font-size: 14px;
}
.assignment-selections ul.items-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
}
.assignment-selections ul.items-list > li .item-inner {
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 50px auto 40px;
  align-items: center;
  padding: 0.5rem;
  --lh: 1.4rem;
  line-height: var(--lh);
  grid-column-gap: 0.25rem;
}
.assignment-selections ul.items-list > li .item-inner.pool-top {
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
}
.assignment-selections ul.items-list > li .item-inner a {
  --max-lines: 2;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  font-size: 13px;
  text-decoration: none;
  display: block;
}
.assignment-selections ul.items-list > li .item-inner .pool-toggle {
  background-color: transparent;
  border: 0;
  grid-column: 4;
}
.assignment-selections ul.items-list > li .item-inner .pool-toggle .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.assignment-selections ul.items-list > li .item-inner .pool-toggle:hover .circle {
  background-color: rgba(217, 217, 217, 0.65);
}
.assignment-selections ul.items-list > li ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 6px 6px;
}
.assignment-selections ul.items-list > li ul li .item-inner {
  display: grid;
  grid-template-columns: 50px auto 40px;
  box-shadow: none;
  border-radius: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  --lh: 1.4rem;
  line-height: var(--lh);
  grid-column-gap: 5px;
  border-bottom: 1px solid #c0c9c1;
}
.assignment-selections ul.items-list > li ul li .item-inner a {
  --max-lines: 2;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  font-size: 13px;
  text-decoration: none;
  display: block;
}
.assignment-selections ul.items-list > li ul li:last-child .item-inner {
  border-radius: 0 0 6px 6px;
}
.assignment-selections ul.items-list > li.pool {
  border-radius: 6px;
  margin-bottom: 10px;
}
.assignment-selections ul.items-list > li.pool > .item-inner {
  border-radius: 6px;
}
.assignment-selections ul.items-list > li.pool.expanded {
  border: 2px solid #047A9C;
}
.assignment-selections ul.items-list > li.pool.expanded > .item-inner {
  background-color: #F2FCFF;
  border-bottom: 1px solid #047A9C;
}
.assignment-selections ul.items-list > li.pool.expanded > .item-inner .circle {
  background-color: #007FA3;
}
.assignment-selections ul.items-list > li.pool.expanded > .item-inner .circle svg {
  fill: #fff;
}
.assignment-selections .selection-summary {
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #c0c9c1;
}
.assignment-selections .selection-summary > div {
  padding: 0 20px;
  border-right: 1px solid #c0c9c1;
  white-space: nowrap;
}
.assignment-selections .selection-summary > div:last-child {
  text-align: right;
  border: 0;
  padding-right: 0;
}
.assignment-selections .selection-summary > div:first-child {
  padding-left: 0;
}

/* Assignment Review */
.review-list {
  border-top: 1px solid #E4E7EB;
  padding: 8px 16px;
  background-color: #fafafa;
}

.review-item {
  border: 0;
  width: 100%;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
  border-radius: 8px;
  margin: 8px 0 16px;
  height: 55px;
  display: grid;
  grid-template-columns: 24px 32px auto auto 100px 48px 36px;
  align-items: center;
  border: 1px solid #fff;
  padding: 4px 8px;
  box-sizing: border-box;
}
.review-item.selected {
  background: #F2FCFF;
  border-color: #047A9C;
  box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
}
.review-item:focus, .review-item:active {
  outline: none;
  box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
}
.review-item .gr-input {
  margin: 0;
  display: inline-block;
  max-width: 40px;
  height: 24px;
  padding: 0 5px;
  margin-right: 5px;
}
.review-item a {
  text-decoration: none;
}

@keyframes review {
  0% {
    flex-basis: 5%;
    flex-grow: 0;
    width: 5%;
  }
  100% {
    flex-basis: 100%;
    flex-grow: 1;
    width: 100%;
  }
}
@keyframes highlightBg {
  0% {
    background-color: rgb(255, 243, 133);
  }
  100% {
    background-color: rgba(255, 243, 133, 0);
  }
}
.filter-container {
  position: absolute;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  right: 0;
  top: calc(100% + 10px);
  border: 1px solid #eee;
  min-width: 400px;
}
.filter-container:after, .filter-container:before {
  bottom: 100%;
  right: 8px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.filter-container:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}
.filter-container:before {
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #eee;
  border-width: 9px;
  margin-right: -1px;
}
.filter-container .gr-form-element {
  margin-bottom: 1rem;
}
.filter-container .actions {
  justify-content: center;
  margin: 2rem -1rem 0 -1rem;
  border-top: 1px solid #E6E6E6;
  padding: 1rem 0 0;
}

/* Inline Filters - Additional filters */
.additional-filter-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
  position: absolute;
  z-index: 15;
  width: 100%;
  right: 0;
  top: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 9px 12px 0px rgba(89, 96, 100, 0.36), 0px 3px 16px 0px rgba(89, 96, 100, 0.31), 0px 5px 6px 0px rgba(89, 96, 100, 0.51);
}
.additional-filter-content h4 {
  margin: 0;
  padding: 8px 12px;
  font-weight: 700;
}

/* Multiselect container */
.multiselect-container {
  /*position: relative; */
  padding: 8px 12px;
}
.multiselect-container:hover {
  background-color: #007FA3;
}
.multiselect-container:hover .multiselect__input {
  background-image: url(../images/icon_right_white.svg);
}
.multiselect-container:hover .multiselect__input span {
  color: #fff;
}
.multiselect-container .multiselect__input {
  /* border: 1px solid #9AA5B1;
  padding: 4px 8px; 
  border-radius: 4px; */
  width: 100%;
  background-color: transparent;
  background-image: url(../images/icon_right.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 24px;
  cursor: pointer;
}
.multiselect-container .multiselect__input.disable_ms {
  background-color: #E9E9E9;
  border-color: #C7C7C7;
}
.multiselect-container .multiselect__input span {
  background: transparent;
  line-height: 1.5;
  color: #252525;
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
}
.multiselect-container .multiselect__options {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 4px;
  z-index: 20;
  box-shadow: 0px 9px 12px 0px rgba(89, 96, 100, 0.36), 0px 3px 16px 0px rgba(89, 96, 100, 0.31), 0px 5px 6px 0px rgba(89, 96, 100, 0.51);
}
.multiselect-container .multiselect__options .back-btn {
  margin: 8px 12px 8px 8px;
  padding-left: 24px;
  font-weight: 700;
  color: #252525;
  background: url(../images/icon_left.svg) no-repeat left center;
}
.multiselect-container .multiselect__options ul {
  list-style-type: none;
  margin: 0;
  padding: 16px 8px 16px 16px;
  max-height: 330px;
  min-height: 172px;
  overflow: auto;
}
.multiselect-container .multiselect__options ul li {
  color: #020917;
  margin-bottom: 16px;
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  line-height: 1.43;
  letter-spacing: 0.15px;
}
.multiselect-container .multiselect__options ul li:last-child {
  margin-bottom: 0;
}
.multiselect-container .multiselect__options ul li input {
  margin-right: 8px;
  margin-top: 5px;
  border: 2px solid #9AA5B1;
}
.multiselect-container .multiselect__options ul li label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.multiselect-container .multiselect__options ul li label svg {
  width: 20px;
  height: auto;
  margin-right: 8px;
}
.multiselect-container .multiselect__options ul li .sub-title {
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: #020917;
  display: block;
  margin-top: 0.5rem;
}
.multiselect-container .multiselect__options .dd-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-top: 1px solid #CFD8DC;
  padding: 16px;
}
.multiselect-container .multiselect__options .dd-actions .link-btn {
  font-weight: 600;
  padding: 0;
}

.selected-filters {
  padding: 6px 0 16px;
}

.multiselect__selections, .selected-filters {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}
.multiselect__selections .label, .selected-filters .label {
  margin-right: 16px;
  font-weight: bold;
  font-size: 12px;
}
.multiselect__selections .chip, .selected-filters .chip {
  padding: 4px 8px;
  background: #CFD8DC;
  margin-right: 8px;
  margin-bottom: 5px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  line-height: 1.4;
  color: #263238;
  border-radius: 30px;
}
.multiselect__selections .chip .custom-close, .selected-filters .chip .custom-close {
  margin: 1px 0 0 5px;
}

.filter-checks {
  display: flex;
  justify-content: space-between;
}

.checkbox-container {
  display: flex;
}
.checkbox-container .gr-form-element label {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}
.checkbox-container .gr-form-element img {
  margin-right: 4px;
  vertical-align: middle;
}

.main-content {
  padding: 1.5rem;
}

.dashboard-2col {
  display: flex;
  gap: 3.25rem;
}
.dashboard-2col .col1 {
  flex: 0 0 37.5rem;
}
.dashboard-2col .col2 {
  flex: 1;
}

.page-title {
  color: #000;
  font-family: "Open Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.wrapper h3 {
  font-family: "inter";
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
.wrapper h4 {
  margin: 0;
}

.due-date {
  border: 0;
  background-color: #00809b;
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  width: 2.375rem;
  height: 2.375rem;
  font-size: 0.75rem;
  font-weight: 600;
}
.due-date .MuiSvgIcon-root {
  color: white;
}

.assgn-status {
  max-width: 2.5rem;
  text-align: center;
  padding: 0 0.25rem;
}
.assgn-status.complete {
  background-color: #5bdda0;
}
.assgn-status.complete .MuiSvgIcon-root {
  color: #007e53;
}
.assgn-status.incomplete {
  background-color: #fee264;
}
.assgn-status.incomplete .MuiSvgIcon-root {
  color: #857100;
}
.assgn-status.locked {
  background-color: #b9c3ff;
}
.assgn-status.locked .MuiSvgIcon-root {
  color: #5066c5;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.book-details {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.piechart-label {
  position: absolute;
  top: 45%;
  left: 66px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}
.piechart-label span {
  display: block;
  font-size: 0.75rem;
  letter-spacing: 6%;
}

/* MUI override */
.MuiCard-root.table-wrapper:hover {
  background: linear-gradient(114.06deg, rgba(254, 254, 254, 0.9019607843) 30%, rgba(254, 254, 254, 0.3019607843) 90%) !important;
}

.donut-chart {
  width: 150px;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
}
.donut-chart > svg {
  transform: rotate(90deg);
}
.donut-chart .score {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
  font-family: Inter;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: center;
}

@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.donut-ring {
  stroke: #EBEBEB;
}

.donut-segment {
  transform-origin: center;
  stroke: #5066c5;
}

.donut-segment-2 {
  stroke: #5066c5;
}

@keyframes donut1 {
  to {
    stroke-dasharray: 1000 0;
  }
}/*# sourceMappingURL=styles.css.map */