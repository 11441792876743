.search-filter-toggle {

    .search-toggle {
        display: flex;
        align-items: center;   
        justify-content: space-between;
        padding: 10px 16px;
        border-bottom: 1px solid #D9D9D9;

        .left, .right {
            display: flex;
            align-items: center;
        }
    }

    .search-filter {
        border-top: 1px solid $color-theme-sys-light-outline-variant;

        .top-line {
            display: flex;
            padding: 1rem 0 0.5rem;            
        }

        .form-element {
            flex: 1 1;
            margin-right: 12px;
        }

        .additional-filters {
            margin: 1.375rem 0 0 0;
            position: relative;
            flex: 1;

            svg {
                position: absolute;
                right: 10px;
                top: calc(50% - 15px);
                fill: $color-theme-text-lighttheme-muted;                
            }
            
            .filter-btn {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: block;
                margin: 4px 0;
                padding: 0 42px 0 12px;
                height: 36px;
                color: $color-theme-text-lighttheme-default;
                border: 1px solid $color-theme-sys-light-outline;
                background: $color-theme-white;
                border-radius: 4px;
                font-size: 14px;
                width: 100%;
                max-width: 100%;
                text-align: left;
            }

            .backdrop {
                z-index: 10;
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
            }
        }
    }
}