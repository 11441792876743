@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter', helvetica, sans-serif;
}
body {
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(137.13deg, #EFEFEF 0%, #4CA8C3 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: cover, cover;
    background-attachment: fixed;
    overflow-x: hidden;

    &.in-ov2 {
        background-color: transparent;
        background-attachment: fixed;
        background-image: url(../images/glow.png);
        background-position: bottom right;
        background-size: cover;

        .main-content {
            margin: 0;

            .page-title {
                margin: 0;
            }
        }
    }
}

.body-wrap {
    display: flex;
    min-height: 100vh; 

    .main-nav {
        flex: 0 0 6.75rem;
    }

    main {
        flex: 1;
        mix-blend-mode: normal;
        min-width: calc(100vw - 6.75rem);

        .wrapper {
            max-width: 64rem;
            margin: 2rem auto;
        }
    }
}

.app-header {
    &.MuiPaper-root {
        background: transparent;
    }
}

.section-header {
    background: $gradient-theme-surfaces-gradient-secondary-light-1, $gradient-theme-surfaces-gradient-secondary-light-0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 0;
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    .search-assignments {
        display: flex;
        align-items: center;
        color: $color-theme-sys-light-on-primary;
        gap: 0.5rem;

        select {
            background-color: $color-theme-sys-light-on-primary;
            border-radius: 22px;
            padding: 0.5rem 2rem 0.5rem 1rem;
            min-width: 15rem;
            margin-right: 1rem;
            color: $color-theme-text-lighttheme-default;
            appearance: none;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position: calc(100% - 8px) center;

        }
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 1rem;

        .MuiButton-root {
            border: 2px solid $color-theme-sys-light-on-primary;
            color: $color-theme-sys-light-on-primary;
        }

        .MuiToggleButton-root {
            border: 0;
            border-radius: 50% !important;

            &.Mui-selected {
                background-color: rgba(254, 254, 254, 0.56);
                color: $color-theme-sys-light-on-secondary-container;
            }
        }
    }
}

.main-content  {
    margin: 2rem 8rem;
}

.fieldset-noborder {
    border: none;
    padding: 0;
    margin: 0;

    legend {
        margin-bottom: 0.5rem;
    }
}

.radio-container{
    padding: 24px 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .radio{
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.875rem;

        label{
            font: $font-typography-body-body-small-emphasis; 
        }
    }

    p {
       font: $font-typography-body-body-small;
       color: $color-theme-text-lighttheme-muted;
       margin-left: 2.25rem;
    }

    &:first-of-type{
        border-bottom: 1px solid $color-theme-sys-light-outline-variant;
    }

    &:not(:first-of-type) {
        border-bottom: 1px solid $color-theme-sys-light-outline-variant;;
    }

    &:last-of-type {
        border-bottom: none;
    }
    .input-container {
        margin-left: 2.25rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}


.form-element {
    label {
        font: $font-typography-inputs-input-label-helper-text-chip;
        color: $color-theme-text-lighttheme-muted;
    }
}
/* Select ---*/
.select-container {
    position: relative;

    svg {
        position: absolute;
        right: 10px;
        top: calc(50% - 15px);
        fill: $color-theme-text-lighttheme-muted;                
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        margin: 4px 0;
        padding: 0 42px 0 12px;
        height: 36px;
        color: $color-theme-text-lighttheme-default;
        border: 1px solid $color-theme-sys-light-outline;
        background: #fff;
        border-radius: 4px;
        font-size: 14px;
        width: 100%;
        max-width: 100%;
    }
}

.old-page {
    background-color: #fff;
    min-height: calc(100vh - 70px);
    padding: 1rem 2rem;

    &.full-page {
        min-height: 100vh;
    }
}